import Vue from 'vue'
import VueRouter from 'vue-router'
const HomeComponent = () => import(/* webpackChunkName: "/" */ '@/components/HomeComponent');
const AboutComponent = () => import(/* webpackChunkName: "about" */ '@/components/AboutComponent');
const FocusAreaComponent = () => import(/* webpackChunkName: "about" */ '@/components/FocusAreaComponent');
const ServiceComponent = () => import(/* webpackChunkName: "about" */ '@/components/ServiceComponent');
const TeamComponent = () => import(/* webpackChunkName: "about" */ '@/components/TeamComponent');
const ContactUsComponent = () => import(/* webpackChunkName: "about" */ '@/components/ContactUsComponent');
const PublicationComponent = () => import(/* webpackChunkName: "about" */ '@/components/PublicationComponent');
const BoardComponent = () => import(/* webpackChunkName: "about" */ '@/components/BoardComponent');
const ProjectComponent = () => import(/* webpackChunkName: "about" */ '@/components/ProjectComponent');
const ProfileComponent = () => import(/* webpackChunkName: "about" */ '@/components/ProfileComponent');
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeComponent
  },
  {
    path: '/about',
    name: 'About',
    component: AboutComponent
  },
  {
    path: '/focus-areas',
    name: 'FocusAreas',
    component: FocusAreaComponent
  },
  {
    path: '/services',
    name: 'Services',
    component: ServiceComponent
  },
  {
    path: '/team',
    name: 'Team',
    component: TeamComponent
  },
  {
    path: '/team',
    name: 'Team',
    component: TeamComponent
  },
  {
    path: '/board',
    name: 'Board',
    component: BoardComponent
  },
  {
    path: '/publications',
    name: 'Publications',
    component: PublicationComponent
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUsComponent
  },
  {
    path: '/projects',
    name: 'Projects',
    component: ProjectComponent
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileComponent
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
