<template>
  <v-app-bar
      class="header px-md-14 px-sm-0"
      text
      style=" background: #0E4A29;">
    <v-app-bar-title>
      <v-avatar size="60" @click="$router.push({name:'Home'})">
        <v-img
            src="@/assets/logo.jpeg"
        />
      </v-avatar>
    </v-app-bar-title>
    <v-spacer/>
    <v-menu
        bottom
        left
        v-if="!showMenu"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            dark
            icon
            v-bind="attrs"
            v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list style="background: #0E4A29">
        <v-list-item><v-btn text class="text-decoration-none font-weight-800 white--text" @click="$router.push({name:'Home'})">Home</v-btn></v-list-item>
<!--        <v-list-item><v-btn text class="text-decoration-none font-weight-800 white&#45;&#45;text" @click="$router.push({name:'FocusAreas'})">What We Do</v-btn></v-list-item>-->
        <v-list-item><v-btn text class="text-decoration-none font-weight-800 white--text" @click="$router.push({name:'Services'})">ABOUT JSB KONKRETE</v-btn></v-list-item>
        <v-list-item><v-btn text class="text-decoration-none font-weight-800 white--text" @click="$router.push({name:'Team'})">WHAT WE DO</v-btn></v-list-item>
        <v-list-item><v-btn text class="text-decoration-none font-weight-800 white--text" @click="$router.push({name:'Publications'})">PRODUCTS</v-btn></v-list-item>
        <v-list-item><v-btn text class="text-decoration-none font-weight-800 white--text" @click="$router.push({name:'ContactUs'})">ContactS</v-btn></v-list-item>
      </v-list>
    </v-menu>
    <div v-if="showMenu">
      <v-btn text class="text-decoration-none font-weight-800 white--text" @click="$router.push({name:'Home'})">Home</v-btn>
<!--      <v-btn text class="text-decoration-none font-weight-800 white&#45;&#45;text" @click="$router.push({name:'FocusAreas'})">What We Do</v-btn>-->
      <v-btn text class="text-decoration-none font-weight-800 white--text" @click="$router.push({name:'Services'})">ABOUT JSB KONKRETE</v-btn>
      <v-btn text class="text-decoration-none font-weight-800 white--text" @click="$router.push({name:'Team'})">WHAT WE DO</v-btn>
      <v-btn text class="text-decoration-none font-weight-800 white--text" @click="$router.push({name:'Publications'})">PRODUCTS</v-btn>
      <v-btn text class="text-decoration-none font-weight-800 white--text" @click="$router.push({name:'ContactUs'})">ContactS</v-btn>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: "HeaderComponent",
  data: () => ({
    toggleMenu: false,
  }),
  computed: {
    showMenu() {
      return !this.$vuetify.breakpoint.mdAndDown
    }
  },
}
</script>

<style>
.header{
  background: #0E4A29;
}
</style>
