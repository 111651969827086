<template>
  <div class="mt-10" style="margin-top: 200px">
    <v-footer
        dark
        padless
        bottom
    >
      <v-card
          class="flex"
          flat
          tile
      >
        <v-card-title class="teal">
          <strong class="subheading">Get connected with us on social networks!</strong>

          <v-spacer></v-spacer>

          <v-btn
              v-for="item in icons"
              :key="item.icon"
              class="mx-4"
              dark
              @click="redirectTo(item.social_media_url)"
              icon
          >
            <v-icon size="24px">
              {{ item.icon }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} — <strong>JSB Konkrete | All Rights Reserved</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  data: () => ({
    icons: [
      {
        icon:'mdi-facebook',
        social_media_url:"https://www.facebook.com/id4sadvisory"
      },
      {
        icon:'mdi-twitter',
        social_media_url:"https://twitter.com/ID4Sadvisorygr1"
      },
      {
        icon:'mdi-linkedin',
        social_media_url:"https://www.linkedin.com/company/id4sadvisory"
      },
      {
        icon:'mdi-instagram',
        social_media_url:"https://instagram.com/id4sadvisory"
      },
    ],
  }),
  methods: {
    redirectTo(url) {
      window.open(url,'_blank');
    }
  }
}
</script>

<style scoped>

</style>
